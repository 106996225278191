import React, { useEffect, useState } from "react";
import SideBar from "../../layouts/SideBar/SideBar";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Invoices from "../../containers/Invoices/Invoices";
import CreditNote from "../../containers/CreditNote/CreditNote";
import InvoiceView from "../../views/Invoice/InvoiceView";
import CreditNotesView from "../../views/CreditNotes/CreditNotesView";
import PaymentView from "../../views/Payment/PaymentView";
import MessagesView from "../../views/Messages/MessagesView";
import RequestingCallView from "../../views/RequestingCall/RequestingCallView";
import RequestingTimeView from "../../views/RequestingTime/RequestingTimeView";
import Token from "../../providers/AuthProvider";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getAuthorizationToken,
  getLayout,
  welcomeTextLayout,
} from "../../services/customerPortal";
import { getLayoutData } from "../../redux/Layout/action";
import SidebarMobileView from "../../layouts/SideBar/SidebarMobileView";
import { HeadingXSmall } from "baseui/typography";
import powerBy from "../../assets/img/pw-by.png";
import PaymentContext from "../../components/PaymentContext/PaymentContext";
import PaymentProcessing from "../../../src/assets/img/payment-processing.png";
import PaymentSuccess from "../../../src/assets/img/payment_success.png";
import PaymentCanceled from "../../../src/assets/img/payment-canceled.png";
import Opps from "../../../src/assets/img/opps.png";
import Paymentfailed from "../../../src/assets/img/payment-failed.png";
import { KIND } from "baseui/button";
import { HeadingXLarge } from "baseui/typography";
import TableSelectedPhoneFooter from "../../components/TableSelectedFooter/TableSelectedPhoneFooter";
import { Helmet } from "react-helmet";
import InvaliedUser from "../../views/Invaild/InvaliedUser";
import MaxyfiLogo from "../../assets/img/svg/MaxyfiLogo";
import _ from "lodash";
import {
  ClaimsAdjustingActuarialServices,
  LoanBroker,
} from "../../constants/IndustrialSector";
import PaymentAmount from "../../containers/PaymentAmount/PaymentAmount";
import OfflinePayment from "../../containers/Payment/OfflinePayment";
import AuthorizeDownload from "../../views/AuthorizeDownload/AuthorizeDownload";

import { useMutation } from "react-query";
import { debtorRightConfirmation } from "../../services/customerPortal";
import { Modal } from "baseui/modal";
import { LabelLarge, ParagraphMedium } from "baseui/typography";
import { TextButton } from "../../components/TextButton";
import { setDebtorRightsModal } from "../../redux/Layout/action";
import CustomizeUiView from "../../views/CustomizeUi/CutomizeUiPortal";
import { Spinner } from "baseui/icon";
import UnSubscribe from "../../views/UnSubscribe/UnSubscribe";
import TableSelectedInvoicePhoneFooter from "../../components/TableSelectedFooter/TableSelectedInvoicePhone";
import { setCustomizeUiData } from "../../redux/CustomizeUI/action";

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
}

function getTextColor(rgba) {
  rgba = rgba.match(/\d+/g);
  if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
    return "#08083a";
  } else {
    return "white";
  }
}

const DebtorRights = ({
  active = false,
  debtorRight = "",
  closeModal = () => {},
  refetch = () => {},
}) => {
  let customerData = useSelector((e) => e.layoutReduceData?.customer);

  const acceptDebtorRights = useMutation(() => debtorRightConfirmation(), {
    onSuccess: (data) => {
      closeModal();
      // refetch();
    },
  });

  return (
    <Modal
      focusLock={false}
      isOpen={active}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            padding: "10px",
            // width: "351px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
    >
      <LabelLarge>Consumer Rights</LabelLarge>

      <div
        style={{
          maxHeight: "500px",
          overflow: "scroll",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <ParagraphMedium
          style={{ textAlign: "left", whiteSpace: "break-spaces" }}
        >
          {debtorRight}
        </ParagraphMedium>
      </div>

      <div>
        {customerData && customerData.is_cp_right ? (
          <TextButton
            size="mini"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </TextButton>
        ) : (
          <TextButton
            size="mini"
            onClick={async () => {
              await acceptDebtorRights.mutateAsync();
            }}
            disabled={acceptDebtorRights.isLoading}
            isLoading={acceptDebtorRights.isLoading}
          >
            I Accept
          </TextButton>
        )}
      </div>
    </Modal>
  );
};

const debtorRightModalInitial = {
  active: false,
  debtorRight: "",
};

const Layout = ({
  data,
  isLoading = true,
  refetch = () => {},
  sideBar = true,
  children,
  setFooter,
  isFooter,
}) => {
  let location = useLocation();
  let dispatch = useDispatch();
  const { ...rest } = useParams();
  const web = process.env.REACT_APP_WEB_LINK;
  const [mobFooter, setMobFooter] = useState(false);
  const regexUsc = /usc\.edu/;
  console.log("location>>", location.pathname.split("/"), rest);
  let eLinkSplit = location?.pathname?.split("/");

  const [debtorRightModal, setDebtorRightModal] = useState({
    ...debtorRightModalInitial,
  });

  const closeDebtorRightModal = () => {
    // setDebtorRightModal({ ...debtorRightModalInitial });
    dispatch(setDebtorRightsModal(false));
    localStorage.setItem("debtor_rights", true);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  let params = searchParams.get("is_internal_call");
  useEffect(() => {
    if (params) {
      localStorage.setItem("is_internal_call", params);
    }
  }, []);

  let loadingSatate = useSelector((e) => e.layoutReduceData.isLoading);
  let refetchData = useSelector((e) => e.layoutReduceData.refetchData);
  const {
    default_nav_color,
    organization,
    customer,
    tabMotion,
    referenceData,
    debtor_rights_modal,
  } = useSelector((s) => s.layoutReduceData);
  let layoutData = useSelector((s) => s.layoutReduceData);
  const { selectedInvoice } = useSelector((s) => s.invoiceReducer);
  const { popUp } = useSelector((e) => e.invoiceReducer);

  console.log("organization", organization);

  // const { data, isLoading, refetch } = useQuery(
  //   ["layoutData", { refetchData }],
  //   async () => {
  //     return await getLayout();
  //   },
  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const { data: isWelcomeData, isLoading: isWelcomeLoading } = useQuery(
    ["WelcomeLayoutData", { refetchData }],
    async () => {
      return await welcomeTextLayout();
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (_.get(isWelcomeData, "data.doc")) {
      console.log(isWelcomeData, "Data fetched and dispatched");
      dispatch(setCustomizeUiData(_.get(isWelcomeData, "data.doc")));
    }
  }, [isWelcomeData, dispatch]);

  useEffect(() => {
    if (data && data.data && data.data.doc) {
      dispatch(getLayoutData(data && data.data && data.data.doc));
    }
  }, [data]);

  useEffect(() => {
    const isClosed = localStorage.getItem("debtor_rights");
    if (
      layoutData?.is_mini_miranda_configured &&
      layoutData?.customer?.debtor_rights &&
      layoutData?.customer?.debtor_rights.length > 0
      // &&
      // !isClosed
    ) {
      setDebtorRightModal({
        ...debtorRightModalInitial,
        active: true,
        debtorRight: layoutData?.customer?.debtor_rights,
      });
      dispatch(setDebtorRightsModal(true));
    }
  }, [layoutData?.is_mini_miranda_configured]);

  const rgba = hexToRgbA(default_nav_color);

  const textColor = getTextColor(rgba);
  if (
    (data &&
      data.data &&
      data.data.doc &&
      data.data.doc.customer &&
      data.data.doc.customer.customer_status === "cancelled") ||
    (data &&
      data.data &&
      data.data.doc &&
      !data.data.doc.is_customer_portal_access)
  ) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Helmet>
          <title>{organization && organization.name}</title>
          <link
            rel="icon"
            href={
              organization &&
              organization.customer_portal_ui &&
              organization.customer_portal_ui.logo
            }
          />
        </Helmet>
        <h4 style={{ fontSize: "20px" }}>Invalid URL</h4>
        <br />
        <h4 style={{ fontSize: "20px", textAlign: "center" }}>
          If you are looking for Debt related Information, Kindly contact us at{" "}
          {_.get(data, "data.doc.organization.email", "") +
            " / " +
            _.get(data, "data.doc.organization.phone.code", "") +
            _.get(data, "data.doc.organization.phone.value", "")}
        </h4>
      </div>
    );
  }
  const getDomainName = (url) => {
    // Remove the protocol (http, https, etc.) and any trailing slashes
    return url.replace(/(^\w+:|^)\/\//, "").replace(/\/$/, "");
  };
  return (
    <>
      <div style={{ height: "100vh" }}>
        <Helmet>
          <title>{organization && organization.name}</title>
          <link
            rel="icon"
            href={
              organization &&
              organization.customer_portal_ui &&
              organization.customer_portal_ui.logo
            }
          />
        </Helmet>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              background: default_nav_color,
            }}
            className="header_payabel"
          >
            {_.get(
              data,
              "data.doc.organization.customer_portal_ui.prfv",
              ""
            ) === "SAV" && location.pathname.split("/").length === 2 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <p
                    className={"org-name-head-line"}
                    style={{
                      color: textColor ? textColor : "#ffffff",
                      fontWeight: "500",
                      lineHeight: "17px",
                    }}
                  >
                    {organization?.name}
                  </p>
                  <div
                    style={{
                      position: "absolute",
                      right: "1%",
                    }}
                  >
                    {/* {console.log(organization?.org_url, "sdshjkhsjkdhsj")} */}
                    <ParagraphMedium
                      $style={{
                        color: textColor ? textColor : "#ffffff",
                        fontWeight: "400",
                        fontSize: "13px",
                        lineHeight: "15.6px",
                        maxWidth: "150px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        textDecoration: "underline",
                        whiteSpace: "nowrap",
                      }}
                      title={organization?.org_url ? organization.org_url : ""}
                      onClick={() => window.open(organization?.org_url)}
                    >
                      {" "}
                      {organization?.org_url
                        ? regexUsc.test(organization?.org_url)
                          ? "FAQ"
                          : organization.org_url
                              .replace(/^https?:\/\//, "")
                              .replace(/\/$/, "")
                        : ""}
                    </ParagraphMedium>
                  </div>
                </div>
              </>
            ) : (
              <LabelLarge
                className={"cus-pay-lab-head"}
                $style={{
                  color: textColor ? textColor : "#ffffff",
                }}
              >
                {_.get(isWelcomeData, "data.doc.welt", "")}
              </LabelLarge>
            )}
          </div>
          {!isLoading &&
          layoutData &&
          organization &&
          organization.industry_sector === LoanBroker ? (
            <div
              className="sidebar-bottom"
              style={{ backgroundColor: "#fff9d5" }}
            >
              <div className="sidebar-footer__pw-by">
                <div className="sidebar-footer__pw-by">
                  <div
                    className="powered-by"
                    style={{
                      color: "#5B5B74",
                    }}
                  >
                    Powered by
                  </div>
                  <HeadingXSmall
                    $style={{ width: "50px", lineHeight: "15px" }}
                    className="logo-max"
                  >
                    {" "}
                    <a href={web} target="_blank" className="credit_href--web">
                      <MaxyfiLogo />
                    </a>
                  </HeadingXSmall>
                </div>
              </div>
            </div>
          ) : null}

          <div
            style={{
              backgroundColor: "#F8F8FB",
              flex: "1",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div className="layout-container" style={{ width: "100%" }}>
              {/* {sideBar && <SideBar />} */}
              {loadingSatate ? children : <></>}
            </div>
            {mobFooter ? (
              <></>
            ) : (
              <>
                {tabMotion === "1" ? (
                  <></>
                ) : (
                  <>
                    {(selectedInvoice &&
                      selectedInvoice.length > 0 &&
                      location.pathname.split("/").length === 2 &&
                      _.get(
                        data,
                        "data.doc.organization.customer_portal_ui.prfv",
                        ""
                      ) !== "SAV") ||
                    (selectedInvoice &&
                      selectedInvoice.length > 0 &&
                      location.pathname.split("/") &&
                      location.pathname.split("/")[2] === "invoices") ? (
                      <>
                        <div
                          className="table-selected-mobile-footer"
                          style={{
                            position: "fixed",
                            bottom: "0",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          {!isFooter ? (
                            <TableSelectedInvoicePhoneFooter
                              setFooter={setFooter}
                              setMobFooter={setMobFooter}
                              mobFooter={mobFooter}
                            />
                          ) : (
                            <TableSelectedPhoneFooter
                              setMobFooter={setMobFooter}
                              mobFooter={mobFooter}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {eLinkSplit?.[2] !== "ELinkUnsubscribe" && debtor_rights_modal ? (
        <DebtorRights
          {...debtorRightModal}
          closeModal={closeDebtorRightModal}
          refetch={refetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const CombineRouter = (props) => {
  const [isFooter, setFooter] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const { data, isLoading, refetch, isFetching } = useQuery(
    "layoutData",
    async () => {
      return await getLayout();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const hash = location.pathname.split("/")[1];

  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  const BlankPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate(`/${hash}`);
    }, []);

    return null;
  };

  let params = searchParams.get("is_internal_call");
  useEffect(() => {
    if (params) {
      localStorage.setItem("is_internal_call", params);
    }
  }, []);

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  return (
    <Layout
      data={data}
      isLoading={isLoading}
      refetch={refetch}
      setFooter={setFooter}
      isFooter={isFooter}
    >
      <Routes>
        <Route
          path="/:hash"
          element={
            _.get(data, "data.doc.organization.customer_portal_ui.prfv", "") ===
            "SAV" ? (
              <CustomizeUiView />
            ) : (
              <InvoiceView setFooter={setFooter} isFooter={isFooter} />
            )
          }
        />
        <Route path="/invalied" element={<InvaliedUser />} />
        <Route path="/:hash/ELinkUnsubscribe" element={<UnSubscribe />} />

        <Route
          path="/:hash/invoices"
          element={<InvoiceView setFooter={setFooter} isFooter={isFooter} />}
        />
        <Route path="/:hash/credit-notes" element={<CreditNotesView />} />
        <Route path="/:hash/payments" element={<PaymentView />} />
        <Route path="/:hash/messages" element={<MessagesView />} />
        <Route
          path="/:hash/requesting-calls"
          element={<RequestingCallView />}
        />
        {(accessValue?.is_request_back ||
          accessValue?.is_request_payment_plan ||
          accessValue?.is_pre_defined_payment_plan) && (
          <Route
            path="/:hash/requesting-times"
            element={<RequestingTimeView />}
          />
        )}

        <Route path="/:hash/customize-ui" element={<CustomizeUiView />} />
        <Route
          path="/:hash/payment-success"
          element={
            <PaymentContext
              title={"Payment is Success"}
              img={PaymentSuccess}
              subText={"Thank you for the confirmation."}
              content={
                "We will Process the Payment against your Invoice shortly"
              }
              btnKind={KIND.secondary}
            />
          }
        />
        <Route
          path="/:hash/payment-processing"
          element={
            <PaymentContext
              title={"Payment Processing..."}
              img={PaymentProcessing}
              subText={"Thank you for the confirmation."}
              content={"We will validate and update the payment status"}
              btnKind={KIND.secondary}
            />
          }
        />
        <Route
          path="/:hash/payment-verified/:paymentId"
          element={
            <PaymentContext
              type="PAYMENT_STATUS_CHECK"
              title={"Payment Processing..."}
              // img={PaymentProcessing}
              subText={"We are checking the payment status..."}
              content={
                "Please do not reload the Page. Until payment Processing Finished"
              }
              btnKind={KIND.secondary}
              isGoBackBtn={false}
            />
          }
        />
        <Route
          path="/:hash/payment-failed"
          element={
            <PaymentContext
              title={"Payment is Failed"}
              img={Paymentfailed}
              subText={"Transaction failed! If your money was debited, "}
              content={
                "Kindly share the confirmation of debit to reconcile the Payment"
              }
              btnKind={KIND.secondary}
            />
          }
        />
        <Route
          path="/:hash/payment-status-unknown"
          element={
            <PaymentContext
              title={"Oops!"}
              img={Opps}
              subText={"It is taking longer than usual"}
              content={"Kindly recheck the Payment Tab after 30 Minutes."}
              btnKind={KIND.secondary}
            />
          }
        />
        <Route path="/:hash/planned-amount" element={<PaymentAmount />} />
        <Route
          path="/:hash/payment-offline"
          element={
            <PaymentContext
              title={"Offline Payment"}
              img={OfflinePayment}
              subText={"the payment will be Offline"}
              content={"Kindly pay Offline the Payment ."}
              btnKind={KIND.secondary}
            />
          }
        />
        <Route
          path="/:hash/authorize/document"
          element={<AuthorizeDownload />}
        />
        <Route path="*" element={<BlankPage />} />
        {/* <Route path="/:hash" element={<Token />} /> */}
      </Routes>
    </Layout>
  );
};

export default CombineRouter;

{
  /* <div className="mobile-view__footer">
        <div className="footer-wraper__text">
          <HeadingXSmall>Powered by </HeadingXSmall>{" "}
          <HeadingXSmall $style={{ width: "50px", paddingTop: "6px" }}>
            <a href={web} target="_blank" className="mobile-view__href">
              <img src={powerBy} />
            </a>
          </HeadingXSmall>
        </div>
      </div> */
}

{
  /* <SidebarMobileView /> */
}
{
  /* </div> */
}
