import { Avatar } from "baseui/avatar";
import {
  HeadingXLarge,
  LabelLarge,
  LabelMedium,
  ParagraphMedium,
} from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import PaymentContext from "../../components/PaymentContext/PaymentContext";
import PaymentPlaned from "../../../src/assets/img/payment_planned.png";
import { FormattedMessage } from "react-intl";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { STYLE_TYPE } from "baseui/checkbox";
import { TextButton } from "../../components/TextButton";
import SinglePayment from "../RequestingTime/SinglePayment";
import PlannedAmount from "../PlannedAmount/PlannedAmount";
import ChoosePlan from "../ChoosePlan/ChoosePlan";
import DateChoose from "../RequestingTime/DateChoose";
import ChoosePlannedSvg from "../../assets/img/svg/ChoosePlannedSVG";
import _ from "lodash";

const PaymentPlan = ({ data, setCount, count }) => {
  // Header start

  const location = useLocation();
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);
  const hash = location.pathname.split("/")[1];
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let organizationData = useSelector((e) => e.layoutReduceData.organization);
  let showActiveUser = addSearchParams.get("i");
  const {
    default_color,
    customer: { total_outstanding_amount = 0 },
  } = useSelector((s) => s.layoutReduceData);
  // Header end
  const [isSelect, setIsSelect] = useState("payment_plan_options");
  const [value, setValue] = useState();
  const [iscount, setIsCount] = useState();

  useEffect(() => {
    if (data) {
      const {
        is_allow_manual_payment_plan,
        is_request_promise_to_pay,
        is_allow_predefined_plan,
        is_promise_to_pay_pending,
        is_payment_plan_pending,
      } = data;
      // const {
      //   is_request_promise_to_pay,
      //   is_allow_predefined_plan,
      //   is_promise_to_pay_pending,
      //   is_payment_plan_pending,
      // } = data;

      if (
        (is_request_promise_to_pay &&
          is_allow_manual_payment_plan &&
          !is_promise_to_pay_pending &&
          !is_payment_plan_pending) ||
        (is_request_promise_to_pay && is_allow_predefined_plan)
      ) {
        setValue("choose_promise_ppa");
      } else if (is_request_promise_to_pay && !is_promise_to_pay_pending) {
        setValue("promise");
      } else if (is_allow_predefined_plan && !is_payment_plan_pending) {
        setValue("payment_plan_options");
      } else {
        setValue("payment_plan_custom");
      }
    }
  }, [data]);

  if (value === "promise") {
    return (
      <>
        {/* BACK BUTTON STARTS */}
        {/* <DateChoose /> */}
        <div style={{ width: "100%" }}>
          <div
            className="payment_title --mobl_title"
            style={{ justifyContent: "center" }}
          >
            <>
              {/* <div
                      className="payment_back_btn"
                      onClick={() => {
                        setIsOutcome("PAYMENTDAYS");
                      }}
                    >
                      <LeftArrow />
                    </div> */}
              {/* BACK BUTTON STARTS */}
              <div
              // style={{
              //   paddingTop: "0.5%",
              //   cursor: "pointer",
              //   position: "absolute",
              //   left: "25%",
              // }}
              // className="payment_back_btn"
              >
                <NavLink
                  to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
                >
                  <div
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      border: "0.5px solid #cdced9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i className="mx-icon-Group-16969" />
                  </div>
                </NavLink>
              </div>
              {/* BACK BUTTON ENDS */}
            </>
            <div style={{ textAlign: "center", maxWidth: "400px" }}>
              <HeadingXLarge>Schedule a promised payment</HeadingXLarge>
              <ParagraphMedium
                $style={{
                  color: "#ADADAD",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Submit a request for additional time to make a payment below. We
                will reach out to you on the requested date to confirm payment
              </ParagraphMedium>
            </div>
          </div>

          <div className=" invoices-container invoices-header-container">
            <SinglePayment data={data} count={count} setCount={setCount} />
          </div>
        </div>
        {/* BACK BUTTON ENDS */}
      </>
    );
  }

  //PPA CUSTOM
  if (value === "payment_plan_custom") {
    return (
      <>
        <div className=" invoices-container invoices-header-container">
          <PlannedAmount
            setIsSelect={setIsSelect}
            items={data}
            isSelect={value}
            setCount={setCount}
            is_allow_auto_debit_plan={_.get(
              data,
              "is_allow_auto_debit_plan",
              false
            )}
            auto_debit_payment_gateways={_.get(
              data,
              "auto_debit_payment_gateways",
              []
            )}
          />
        </div>
      </>
    );
  }
  //Choose Promise/PPA
  if (value === "choose_promise_ppa") {
    return (
      <div className=" invoices-container invoices-header-container">
        <div style={{ background: "#fff", textAlign: "center", height: "100" }}>
          <div>
            <div
              className="payment_title --mobl_title"
              style={{
                justifyContent: "center",
              }}
            >
              <>
                {/* <div
                      className="payment_back_btn"
                      onClick={() => {
                        setIsOutcome("PAYMENTDAYS");
                      }}
                    >
                      <LeftArrow />
                    </div> */}
                {/* BACK BUTTON STARTS */}
                <div
                  // style={{
                  //   paddingTop: "0.5%",
                  //   cursor: "pointer",
                  //   position: "absolute",
                  //   left: "25%",
                  // }}
                  className="payment_back_btn --changed"
                >
                  <NavLink
                    to={`/${hash}${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`}
                  >
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        border: "0.5px solid #cdced9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i className="mx-icon-Group-16969" />
                    </div>
                  </NavLink>
                </div>
                {/* BACK BUTTON ENDS */}
              </>

              <div>
                <LabelLarge
                  $style={{
                    textAlign: "center",
                  }}
                  // className="org_name"
                  className="payment-list-org_name"
                >
                  {/* Pay Seshavalli Vaishnava matriculation school */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      // height: "27px",
                      gap: 9,
                      alignItems: "center",
                    }}
                  >
                    <LabelLarge>Payment Options</LabelLarge>
                  </div>
                  {/* {orgName ? orgName : ""} */}
                </LabelLarge>
                <ParagraphMedium $style={{ color: "#ADADAD" }}>
                  {/* Need additional time to make payment? */}
                  Select from the options below
                </ParagraphMedium>
              </div>
            </div>
          </div>
          <div style={{ height: "70vh" }}>
            {/* <img
              src={PaymentPlaned}
              alt="payment_plan"
              className="paymentPlan"
            /> */}
            <ChoosePlannedSvg />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "70px",
                paddingBottom: "2%",
              }}
            >
              <RadioGroup
                value={isSelect}
                onChange={(e) => setIsSelect(e.currentTarget.value)}
                name="number"
                align={ALIGN.horizontal}
              >
                <Radio value="promise">Schedule promise to pay</Radio>
                <Radio value="payment_plan_options">
                  Multiple payment plan
                </Radio>
              </RadioGroup>
            </div>

            <div
              style={{
                flex: "1",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex",
              }}
            >
              <div style={{ width: "150px" }}>
                <TextButton
                  fullWidth
                  size={SIZE.compact}
                  type="button"
                  kind={KIND.primary}
                  // fullWidth
                  // style={{
                  //   height: "48px",
                  //   width:"40px"
                  // }}
                  disabled={isSelect == undefined}
                  onClick={() => {
                    if (
                      isSelect == "payment_plan_options" &&
                      data &&
                      data.is_allow_predefined_plan
                    ) {
                      setValue("payment_plan_options");
                    } else if (isSelect == "payment_plan_options") {
                      setValue("payment_plan_custom");
                    } else if (isSelect == "promise") {
                      setValue("promise");
                    }
                  }}
                >
                  <span
                    style={{
                      // mixBlendMode: "difference",
                      color: "#ffffff",
                      fontSize: "14px",
                    }}
                  >
                    Next
                  </span>{" "}
                </TextButton>
              </div>
            </div>

            {/* <TextButton size={SIZE.mini}  kind={KIND.primary } >
                      Next
                  </TextButton> */}
            {/* <IconButton kind={KIND.}>
      Next
                  </IconButton> */}
          </div>
        </div>
      </div>
    );
  }
  if (value === "select_date") {
    return (
      <DateChoose
        data={data}
        iscount={iscount}
        value={value}
        setValues={setValue}
        is_allow_auto_debit_plan={_.get(
          data,
          "is_allow_auto_debit_plan",
          false
        )}
        auto_debit_payment_gateways={_.get(
          data,
          "auto_debit_payment_gateways",
          []
        )}
      />
    );
  }
  //Payment Plan
  if (value === "payment_plan_options") {
    return (
      <>
        <div
          style={{
            background: "#fff",
            textAlign: "center",
            height: "100",
            flex: "1",
          }}
        >
          <div
            className="payment_title --mobl_title"
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <>
              {/* BACK BUTTON STARTS */}
              <div className="payment_plan_back_btn --chooseplanbtn">
                <NavLink to={`/${hash}`}>
                  <div
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      border: "0.5px solid #cdced9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i className="mx-icon-Group-16969" />
                  </div>
                </NavLink>
              </div>
              {/* BACK BUTTON ENDS */}
            </>

            <div>
              <span className="choose_paymnet_plan">
                Choose from the options below
              </span>
            </div>
          </div>
          <ChoosePlan
            setIsSelect={setIsSelect}
            data={data}
            setValue={setValue}
            setIsCount={setIsCount}
            is_allow_manual_payment_plan={data?.is_allow_manual_payment_plan}
          />
        </div>
      </>
    );
  }

  return <></>;
};

export default PaymentPlan;
